<template>
  <div class="mr-1">
    <v-text-field
      v-model="globalFilterComp"
      dense
      @keydown.enter="search"
      placeholder="Búsqueda"
      hide-details
    >
      <template slot="prepend">
        <v-icon size="22" class="mt-1">search</v-icon>
      </template>

      <template slot="append">
        <v-btn icon small @click="search">
          <v-icon size="22">arrow_forward</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    globalFilter: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    lastGlobalFilter: null,
  }),
  mounted() {
    if (this.value) this.lastGlobalFilter = this.globalFilterValue;
  },
  computed: {
    globalFilterComp: {
      get() {
        return this.globalFilter;
      },
      set(newValue) {
        this.$emit("updateGlobalFilterValue", newValue);
      },
    },
  },
  methods: {
    search() {
      if (this.lastGlobalFilter !== this.globalFilter) {
        this.$emit("search");
        this.lastGlobalFilter = this.globalFilter;
      }
    },
  },
};
</script>

<style scoped></style>
